/* * * * * * * * * * * * * * * * * * * * *
 * forms/fieldset/input styles           *
 * * * * * * * * * * * * * * * * * * * * */
/* THESE ARE SHARED GLOBAL STYLE SHEETS - BE AWARE THAT ANY CHANGE WILL BE SITE WIDE */
/* Please keep area specific styles isolated to your area */
/* Form sections */
.form-section {
  background-color: transparent;
  border-color: transparent;
}

.form-section~.form-section {
  margin-top: 2rem;
}

.form-section .faux-material-form-group {
  /* Removes spacing for form groups inside form sections */
  margin-top: 0;
}

.form-section-title {
  font-weight: bold;
  margin: 0;
}

.form-section-title em {
  font-weight: normal;
}

/* Form groups - deals with complications added by our formGroup validation directive */
.faux-material-form-group>[ng-transclude] {
  width: 100%;
}

/* Labels */
.faux-material-form-group label.form-label,
.faux-material-form-group label.radio-inline,
.faux-material-form-group label.checkbox-inline,
.faux-material-form-group .form-label,
.faux-material-form-group .radio-inline,
.faux-material-form-group .checkbox-inline,
.form-label,
.radio-inline,
.checkbox-inline {
  color: var(--rps-text-grey);
}

fieldset:disabled label.form-label,
fieldset:disabled label.radio-inline,
fieldset:disabled label.checkbox-inline,
fieldset:disabled .form-label,
fieldset:disabled .radio-inline,
fieldset:disabled .checkbox-inline,
.faux-material-form-group label.form-label.disabled,
.faux-material-form-group label.radio-inline.disabled,
.faux-material-form-group label.checkbox-inline.disabled,
.faux-material-form-group .form-label.disabled,
.faux-material-form-group .radio-inline.disabled,
.faux-material-form-group .checkbox-inline.disabled,
.form-label.disabled,
.radio-inline.disabled,
.checkbox-inline.disabled {
  color: #d9d9d9;
}

.form-label.required:before,
.required:before,
.radio-inline.required:before,
.checkbox-inline.required:before {
  content: " *";
  color: red;
}

.checkbox-inline {
  padding-left: 0;
}

/* Form inputs */
.faux-material-form-group input.form-control:not(.revert),
.faux-material-form-group select.form-control:not([size]):not([multiple]),
.faux-material-form-group div.form-control,
input.form-control:not(.revert),
select.form-control:not([size]):not([multiple]),
div.form-control {
  height: 30px;
  padding: 0;
}

textarea.form-control {
  height: 5.5em;
  resize: none;
}

.input-group-btn .btn {
  min-width: auto;
}

/* .revert attempts to reset the changes from other style sheets so that input groups can be used */
.revert-input-group .input-group .faux-material-form-group,
.revert-input-group .input-group .form-control,
.revert-input .form-control {
  background-image: none;
}

.revert-input-group .input-group input.form-control,
.revert-input input.form-control,
.revert-input select.form-control {
  padding: 6px 12px;
  height: 34px;
}

.revert-input-group .input-group .input-group-btn .btn {
  padding: 6px 12px;
  margin: inherit;
  border-radius: 4px;
  font-weight: 400;
  line-height: 1.42857143;
  border: 1px solid #adadad;
}

.revert-input-group .input-group.date-picker .input-group-btn {
  padding: 0;
}

.revert-input-group .input-group .input-group-btn:last-child>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.revert-input-group .input-group .input-group-btn:first-child>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Overriding the color of file chooser button styled with form-control */
input[type=file].form-control::file-selector-button {
  background-color: var(--bs-gray-200) !important;
}

input[type=file].form-control:hover::file-selector-button {
  background-color: var(--bs-gray-300) !important;
}