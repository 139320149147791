/* * * * * * * * * * * * * * * * * * * * *
 * Site-specific styles                  *
 * * * * * * * * * * * * * * * * * * * * */
/* THESE ARE SHARED GLOBAL STYLE SHEETS - BE AWARE THAT ANY CHANGE WILL BE SITE WIDE */
/* Please keep area specific styles isolated to your area */
html {
  font-size: 14px;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  background-color: var(--white);
  color: var(--rps-dark-grey);
}

/* show toasts even when scrolled down on page */
div#toast-container {
  position: fixed;
}

/* typography */
.text-huge {
  font-size: 3rem;
}

.text-large {
  font-size: 1.1rem;
}

/* Bootstrap table extensions */
.table.table-interactive tbody tr {
  cursor: pointer;
}

.table.no-borders td {
  border-top: 0;
}

.table.outer-borders tbody {
  border: 1px solid var(--white-100);
}

.table.table-white,
.table.table-white td {
  background-color: white;
  color: var(--grey-600);
}

.table.table-medium {
  background-color: var(--rps-grey-disabled);
  color: var(--white);
}

.table thead.thead-white {
  background-color: white;
  color: var(--grey-600);
}

.table thead.thead-medium {
  background-color: var(--rps-grey-disabled);
  color: var(--white);
}

/* button styling */
.btn-default,
.btn-action,
.btn-secondary {
  border: 1px solid #d7d7d7;
  border-radius: 2px;
  font-size: .9rem;
  margin: 5px;
  min-width: 125px;
  padding: 8px;
  text-align: center;
  text-transform: none;
}

.input-group .btn.btn-action,
.input-group .btn.btn-default,
.input-group .btn.btn-secondary {
  margin: 0;
  min-width: 50px;
}

.btn:disabled,
.btn.btn-normal:disabled,
.btn.btn-secondary:disabled,
.btn.btn-action:disabled {
  background-color: white;
  border-color: #e4e4e4;
  color: var(--grey-850);
  font-weight: bold;
}

.btn:disabled:hover {
  background-color: white;
}

.btn.btn-normal {
  background-color: #f2f2f2;
}

.btn.btn-onwhite {
  color: #00ad78;
}

.btn.btn-secondary {
  color: var(--black);
  background-color: white;
}

.btn.btn-normal:not([disabled]):hover {
  background-color: #e4e4e4;
}

.btn.btn-action,
.btn.btn-primary {
  background-color: var(--rps-dark-green);
  border-color: var(--rps-dark-green);
  color: white;
}

.btn.btn-action:not([disabled]):hover {
  background-color: var(--rps-orange);
  border-color: var(--rps-orange);
}

.btn.btn-no-side-margin {
  margin-left: 0;
  margin-right: 0;
}

.btn.btn-bigger {
  font-weight: bold;
  min-width: 200px;
  text-transform: uppercase;
}

button.btn.fat,
button.btn.wide {
  width: 100%;
  max-width: 450px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

button.btn.wide {
  max-width: initial;
}

/* links */
a,
a:not([href]):not([tabindex]) {
  color: var(--rps-dark-green);
}

a,
a:not([href]) {
  cursor: pointer;
}

a:hover,
a:focus,
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: var(--rps-dark-green);
  text-decoration: underline;
}

a.link-color-inherit {
  color: inherit !important;
}

/* font awesome styles */
.fa.icon-md {
  font-size: 1.25rem;
  padding: 0 0.25rem;
}

.fa.icon-lg {
  font-size: 1.75rem;
  padding: 0 0.25rem;
}

.fa.fa-action {
  color: var(--rps-green);
  font-size: 1.25em;
  vertical-align: middle;
}

/* Pills */
.pill {
  align-items: center;
  border: 1px solid var(--rps-dark-grey);
  border-radius: 3px;
  font-size: .9rem;
  padding: 0.25rem 0.5rem;
}

.pill.borderless-pill {
  border: none;
}

.pill a.fa {
  color: inherit !important;
  text-decoration: none;
}

.pill .fa:last-child {
  padding-left: 0.75rem;
}

/* no script */
.noscript {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #15986C;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.noscript .message-box {
  width: 600px;
  height: 300px;
  border-radius: 5px;
  background-color: var(--white);
}

.noscript img {
  width: 100px;
  height: 50px;
}

.noscript h5 {
  color: black;
}

/*
    Label Statuses
*/
span.label.label-status {
  font-size: .9rem;
  font-weight: normal;
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: left;
  margin-right: 1rem;
}

span.label.label-status .fa {
  margin-right: .5rem;
  margin-left: -.15rem;
}

span.label.label-success {
  background-color: #3F9C35;
}

span.label.label-info {
  background-color: #8B8D8F;
}

span.label.label-warning {
  background-color: #EC7A08;
}

span.label.label-danger {
  background-color: #CC0000;
}

/* Google Places API Autocomplete */
.pac-container {
  z-index: 1100;
}

/* pagination control */
.pagination .page-item .page-link {
  color: var(--rps-dark-green);
}

.pagination .page-item .page-link:hover {
  text-decoration: none;
  background-color: #E8ECEF;
}

.pagination .page-item.active .page-link,
.pagination .page-item.active span,
.pagination .page-item.active .page-link:hover,
.pagination .page-item.active span:hover,
.pagination .page-item.active .page-link:focus,
.pagination .page-item.active span:focus {
  color: var(--white);
  background-color: var(--rps-dark-green);
  border-color: var(--rps-dark-green);
  text-decoration: none;
}

.pagination .page-item .page-link:active {
  box-shadow: 0 0 0 0.1rem var(--rps-orange);
}

ul.pagination {
  margin: auto; // fix for bootstrap browser reset setting this to 1rem;
}

// Only show month/year in ngbDatePicker
div.months-only-datepicker {
  div.ngb-dp-months {
    display: none !important;
  }

  div.ngb-dp-header {
    min-width: 15em;
  }
}

/*
    IE doesn't auto-wrap the contents of nested flexboxes unless the outer child
    has a width

    div.d-flex
      - div.whatever
      - div.whatever        <- without a defined width, this container will grow as wide as it takes to fit all the (grand)children
        - random-component
            - div.d-flex    <- this will not wrap contents unless parent has a width
                - div
                - div
                - div       <- should wrap to new row but doesn't
                - div

    The same-ish bug affects standard text wrapping inside flexbox children,
    even without nesting flexboxes.

    We can force IE to realize that the child shouldn't be larger than the parent
    by applying "align-self: stretch", which sets the flex start/end edges to
    match the parent. This does preclude using any other alignment, but if your
    container is large enough to fill the parent, you shouldn't notice a difference.

    IE: This is why we can't have nice things
*/
.flex-child-wrap-ie-fix {
  align-self: stretch;
}

.row>.flex-child-wrap-ie-fix,
.d-flex>.flex-child-wrap-ie-fix {
  /* Cribbed from Bootstrap to make wrap-fix stuff keep the same flex behavior */
  display: flex;
}

.row>.flex-child-wrap-ie-fix {
  /* Cribbed from Bootstrap to make wrap-fix stuff keep the same wrap behavior */
  flex-wrap: wrap;
}

.flex-column>.flex-child-wrap-ie-fix {
  /* Cribbed from Bootstrap to make wrap-fix stuff keep the same flex direction */
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}