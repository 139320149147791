// "Throbbing boxes" (skeleton CSS)
.loading {
  font-size: 0;
  line-height: 0;

  .text {
    display: table;
    background-color: var(--grey-350);
    color: var(--grey-350);
    height: 18px;
    min-width: 100px;
    margin-top: .4rem;
    border-radius: 6px;
    opacity: 0.1;
    animation: fading 1.5s infinite;
    font: menu;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.link {
      background-color: var(--blue-link);
      opacity: .4;
    }

    &.line {
      width: 100%;
    }

    &.category {
      width: 100px;
      margin-bottom: 10px;
    }
  }

  h1.text {
    height: 28px;
  }

  h2.text {
    height: 25px;
  }

  h3.text {
    height: 22px;
  }

  h4.text {
    height: 20px;
  }

  .image {
    background-color: var(--white-900);
  }
}

@keyframes fading {
  0% {
    opacity: .1;
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: .1;
  }
}