/* * * * * * * * * * * * * * * * * * * * *
 * style overrides for uib library       *
 * * * * * * * * * * * * * * * * * * * * */
/* THESE ARE SHARED GLOBAL STYLE SHEETS - BE AWARE THAT ANY CHANGE WILL BE SITE WIDE */
/* Please keep area specific styles isolated to your area */
.btn.uib-clear,
.btn.uib-close {
  color: var(--rps-text-grey) !important;
}

table>caption {
  padding: initial;
  color: var(--rps-text-grey);
  text-align: center;
  caption-side: top;
}

/* Overriding the color of the bootstrap icon-link class for delete icon */
.icon-link.delete-icon {
  color: var(--rps-dark-green) !important;
  display: inline-block;

  &:hover {
    color: var(--rps-orange) !important;
  }
}

/* accordion */
ngb-accordion.accord-clean .accordion-item {
  border-radius: 0;
  box-shadow: 1px 1px 1px 1px rgba(210, 210, 210, 0.3);
  border: none;
  color: var(--rps-text-grey);
  background-color: #f9f9f9;
}

ngb-accordion.accord-clean .accordion-item:not(:first-of-type) {
  margin-top: 0.75em;
}

ngb-accordion.accord-clean .accordion-item:not(:last-of-type) {
  margin-bottom: 0.75em;
}

ngb-accordion.accord-clean .accordion-item>.accordion-header {
  background-color: #e3efe9;
  padding: 0;
  border: none;
}

ngb-accordion.accord-clean .accordion-item>.accordion-header>.accordion-button {
  background-color: #e3efe9;
  color: var(--rps-text-grey);
}

ngb-accordion.accord-clean .accordion-item>.accordion-header.active {
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

ngb-accordion.accord-clean .accordion-item>.accordion-header>a,
ngb-accordion.accord-clean .accordion-item>.accordion-header>h5>button.btn-link {
  color: var(--rps-text-grey);
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  text-transform: uppercase;
}

ngb-accordion.accord-clean .accordion-item>.accordion-header>h5>button.btn-link {
  text-align: left;
  width: 100%;
}

ngb-accordion.accord-clean .accordion-item>.accordion-header>a:hover,
ngb-accordion.accord-clean .accordion-item>.accordion-header>h5>button.btn-link:hover {
  background-color: #f8f8f8;
}

ngb-accordion.accord-clean .accordion-item>.accordion-body {
  padding-bottom: 5px;
}

ngb-accordion.accord-clean.accord-padded .accordion-item>.accordion-body {
  padding-left: 35px;
  padding-right: 35px;
}

@media(max-width: 767px) {
  ngb-accordion.accord-clean.accord-padded .accordion-item>.accordion-body {
    padding-left: 0;
    padding-right: 0;
  }
}

/* datepicker */
ngb-datepicker ngb-datepicker-navigation .btn-link {
  border: 0;
}

/* pagination */
ngb-pagination .page-link.disabled,
ngb-pagination .disabled>.page-link {
  background-color: white;
}

/* modal */
.modal {
  /* the modal backdrop has a z-index of 1055; the default z-index for .modal is 1050. this fixes the backdrop overlaying the modal */
  z-index: 1060;
}

.modal-dialog {
  background-color: white;
  color: var(--rps-dark-grey);
}

.modal-dialog .container-fluid,
.modal-dialog .container {
  padding: 0;
  margin: 0;
}

.modal-dialog .modal-header {
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 0;
}

.modal-header .modal-title {
  width: 100%;
}

.modal-dialog .modal-body .modal-textarea {
  border: 1px solid black;
  border-radius: 5px;
  height: 275px;
  margin: 6px auto;
}

.modal-dialog .modal-body .modal-textarea-fat {
  border: 1px solid #d7d7d7;
  border-radius: 0;
  min-height: 275px;
  width: 100%;
  margin: 6px auto;
}

.modal-dialog .modal-footer .btn {
  background-color: white;
  border: none;
  min-width: fit-content;
  height: auto;
  padding: 0 0.5em;
  text-transform: uppercase;
}

.modal-dialog .modal-footer .btn.btn-normal {
  color: var(--rps-dark-grey);
}

.modal-dialog .modal-footer .btn:disabled {
  cursor: not-allowed;
}

.modal-dialog .modal-footer .btn.btn-action:not([disabled]) {
  color: var(--rps-dark-green);
  background-color: var(--white);
}

.modal-dialog .modal-footer .btn:not([disabled]):hover,
.modal-dialog .modal-footer .btn.btn-action:not([disabled]):hover {
  background-color: #e4e4e4
}

.modal-dialog .modal-content {
  border-radius: 0;
}

.modal-dialog .modal-footer.revert-btn .btn {
  border-radius: 4px;
  padding: 6px 12px;
  margin: 10px 1px;
}

.modal-dialog .modal-footer.revert-btn .btn.btn-action {
  background-color: var(--rps-dark-green);
  border-color: var(--rps-dark-green);
  color: white;
}

.modal-dialog .modal-footer.revert-btn .btn.btn-default {
  border: 1px solid #d7d7d7;
}

.modal-dialog .modal-footer.revert-btn .btn.btn-action:hover {
  background-color: var(--rps-orange);
}

/* custom checkboxes and radios */
.form-check .form-check-input:checked {
  background-color: var(--rps-dark-green);
  border-color: var(--rps-dark-green);
}

.form-check .form-check-input:checked:disabled {
  background-color: rgba(0, 128, 0, .35);
  border-color: rgba(0, 128, 0, .35);
}

.form-check .form-check-input {
  width: 16px;
  height: 16px;
}

.form-check .form-check-label {
  vertical-align: middle;
}

/*
*   TEXT OVERRIDES
*/
.text-success {
  color: var(--rps-green) !important;
}

.text-danger {
  color: var(--red) !important;
}

/*
*   Custom classes to pass to certain components
*/
// For ngbTypeAhead
.typeahead-dropdown-base {
  border-radius: 0;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  padding: 0;
  border: none;

  .dropdown-item {
    color: var(--rps-dark-grey);
  }

  .dropdown-item:nth-child(2n+1) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .dropdown-item.active {
    background-color: var(--rps-blue);
    color: var(--white);
    font-weight: bold;
  }
}

// ngbTypeAhead product-search-bar (set to popupClass)
.typeahead-dropdown-product-search {
  @extend .typeahead-dropdown-base;

  .dropdown-item {
    font-size: 1.1rem;
    padding: 0.75rem 1.5rem;
  }
}

// ngbTypeAhead autocomplete-dropdown-combo (set to popupClass)
.typeahead-dropdown-autocomplete {
  @extend .typeahead-dropdown-base;
  max-height: 400px;
  overflow-y: auto;
}

// ngbModal round border (set to windowClass of the modal option)
.modal-rounded-window {
  .modal-dialog {
    border-radius: 5px;
  }
}

.table thead th {
  background-color: var(--rps-grey-disabled);
  color: var(--white);
}

.dropdown-item {
  &:hover {
    background-color: #E8ECEF;
    color: white;
  }

  &:focus,
  &:active {
    background-color: var(--rps-blue);
    color: white;
  }
}