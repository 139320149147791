/* * * * * * * * * * * * * * * * * * * * *
 * replacement styles for bootstrap      *
 * material                              *
 * * * * * * * * * * * * * * * * * * * * */
/* THESE ARE SHARED GLOBAL STYLE SHEETS - BE AWARE THAT ANY CHANGE WILL BE SITE WIDE */
/* Please keep area specific styles isolated to your area */
.faux-material-form-group {
  margin: 28px 0 0;
  padding-bottom: 7px;
  position: relative;
}

.form-label {
  margin: 16px 0 0;
}

label.checkbox-label {
  color: var(--rps-text-grey);
}

.has-success .form-label {
  /* TODO: Remove the !important after merging with the rest of our CSS */
  color: var(--rps-green) !important;
}

.has-error .form-label {
  /* TODO: Remove the !important after merging with the rest of our CSS */
  color: var(--red) !important;
}

.has-error .help-block {
  color: var(--red);
}

.has-error .help-block::before {
  /*
     * TODO: Move the font (and probably the content, too) out into variables so
     * we don't have the font hard-coded in some random CSS file (after merging
     * with the rest of our CSS, of course)
     */
  content: "\f071";
  font-family: FontAwesome;
}

.form-control:not(.revert) {
  border-radius: 0;
  border-width: 0 0 2px;
  border-style: solid;
  border-color: rgba(226, 226, 226, .6);
  box-shadow: none;
  margin-bottom: 0;
  /* bottom margin screws up input groups */
  padding: 0;
}

.input-group input.form-control {
  margin-bottom: 0;
  /* bottom margin screws up input groups */
}

span.form-control {
  padding: 5px 0 4px;
}

textarea.form-control {
  border-width: 2px;
  padding: 3px;
}

.form-control:focus,
.has-success .form-control:focus,
.has-error .form-control:focus {
  /* TODO: Remove borders from other styles (i.e., .modal-textarea-fat) and remove !important here */
  border-color: rgba(102, 175, 233, .6) !important;
  box-shadow: none;
}

.has-success .form-control {
  border-color: #00b37d;
  box-shadow: none;
}

.has-error .form-control {
  border-color: var(--red);
  box-shadow: none;
}

.radio label,
label.radio-inline {
  cursor: pointer;
  padding-left: 45px;
  position: relative;
}

label.radio-inline input[type=radio] {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}

label.radio-inline span,
label.radio-inline button {
  display: block;
  position: absolute;
  left: 10px;
  top: 2px;
  transition-duration: .2s;
}

.radio-control-circle.focus,
.radio-control input:checked~.radio-control-circle.focus {
  /* TODO: Remove the !important after merging with the rest of our CSS */
  border-color: rgba(33, 134, 222, .4) !important;
}

.checkbox-control-container.focus,
.radio-control input:checked~.checkbox-control-container.focus {
  /* TODO: Remove the !important after merging with the rest of our CSS */
  border-color: rgba(33, 134, 222, .4) !important;
}

legend {
  border-bottom: 0;
  margin-bottom: 22px;
}

.input-group-btn button {
  border: 0;
  border-radius: 0;
  margin: 0 0 7px 0;
  background-color: var(--white);
}

.input-group-btn button:focus {
  background-color: transparent;
}

.input-group-addon {
  background-color: transparent;
  border-color: transparent;
}

.modal-header,
.modal-footer {
  border: 0
}