/* * * * * * * * * * * * * * * * * * * * *
 * Product workflow process styles       *
 * * * * * * * * * * * * * * * * * * * * */
/* This does not belong in layout but apparently in MyRpsV2 we were including it with everything so
 until what's actually in use is sorted out, it has to stay here. */
/* Content containers */
.content-box {
  background-color: white;
  color: var(--rps-text-grey);
}

.content-box.transparent {
  background-color: transparent;
}

.content-box.box-margin {
  border: 1px solid #e4e4e4;
  margin: 1em 0;
}

.content-box.box-padding {
  padding: 2em 0;
}

.content-box.box-padding .expand-out {
  margin-left: -4em;
  margin-right: -4em;
}

@media(max-width: 1199px) {
  .content-box.box-padding {
    padding: 0.5em 1em;
  }

  .content-box.box-padding .expand-out {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.content-header {
  background-color: white;
  border: 2px solid #ffa000;
  margin-bottom: 1em;
  margin-top: 1em;
  padding: 1em;
}

.content-header .fa {
  color: #ffa000;
}

/* hasbro ("new" workflow styling) */
.hasbro .btn.btn-action:not(.btn-revert) {
  background-color: var(--rps-orange);
  border-color: var(--rps-orange);
}

.hasbro .btn.btn-action:not([disabled]):not(.btn-revert):hover {
  background-color: #F4A064;
  border-color: #F68C3E;
}

/*ngb-dp-arrow-btn - to prevent styles applied to the arrow buttons of a date picker component*/
.hasbro .btn:not(.input-group-btn.btn):not(.date-picker.btn):not(.ngb-dp-arrow-btn):not(.btn-revert):not(.btn-effective):not(.toggle-btn):not(.btn-add-form) {
  min-width: 175px;
  min-height: 40px;
  border-radius: 5px;
}

.hasbro .faux-material-form-group .form-control {
  padding: 6px 8px;
  border: 1px solid var(--grey-850);
  border-radius: 4px;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, .075);
}

.hasbro .faux-material-form-group input.form-control {
  height: 30px;
  /* needs to be the same height as form-control in forms.scss */
  padding-left: 1rem;
}

.hasbro .faux-material-form-group .input-group input.form-control {
  padding-left: 1rem;
}

.hasbro .faux-material-form-group .input-group input.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-color: var(--rps-blue) !important;
}

.hasbro .faux-material-form-group .form-label,
.hasbro .label-block {
  margin-bottom: 10px;
  display: block;
  text-align: center;
  font-size: 1.1rem;
}

.hasbro .faux-material-form-group .form-label.label-left {
  text-align: left;
}

.hasbro .faux-material-form-group .form-label.label-right {
  text-align: right;
}

.hasbro .deemphasized {
  color: var(--grey-600) !important;
  /* Damn base label styles getting all up in my grill */
}

.hasbro .pill.deemphasized {
  border-color: var(--grey-600);
}

.hasbro .form-label.deemphasized {
  margin-bottom: 2px;
}

/* new quote smell sidebar (chubb bop) */
.hasbro .sidebar hr {
  border-color: #D7D7D7;
  width: 100%;
}

.hasbro .sidebar a.download-link {
  color: #149DCC;
  text-decoration: underline;
}

.hasbro .sidebar a.download-link.disabled {
  color: #8B8D8F;
  cursor: not-allowed;
  text-decoration: none;
}

.hasbro .sidebar .content-left {
  text-align: left;
}

.hasbro .sidebar .premium-total {
  font-size: 1.75rem;
}

/* Hasbro Bootstrap tabs */
.hasbro .nav-tabs {
  border-bottom: transparent;
}

.hasbro .nav-tabs .nav-item .nav-link,
.hasbro .nav-tabs .nav-item .nav-link:hover {
  background-color: rgba(40, 114, 79, .4);
  color: var(--rps-grey-disabled);
}

.hasbro .nav-tabs>.nav-item>.nav-link.active,
.hasbro .nav-tabs>.nav-item>.nav-link.active:hover {
  background-color: var(--rps-dark-green);
  color: white;
  border-color: transparent;
}

/* sidebar tools */
a.tools-link,
a.tools-link:not([href]) {
  cursor: pointer;
  color: #009DD9;
  margin: 1em 0;
  text-align: center;
  text-decoration: underline;
}

a.tools-link:not([href]):hover {
  color: #009DD9;
}

a.tools-link img {
  display: block;
  margin: 0.5em auto;
  max-height: 90px;
  max-width: 90px;
}

a.tools-link.disabled {
  color: #8B8D8F;
  cursor: not-allowed;
  text-decoration: none;
}

a.tools-link .tools-link-message {
  font-size: .9rem;
  margin-top: 0.5em;
}

.non-radiused-panel {
  margin-top: 0.5em;
  margin-bottom: 20px;
  background-color: var(--white);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

/* Sidebar styles */
.sidebar {
  background-color: white;
  color: var(--rps-text-grey);
  padding: 1em;
  overflow-wrap: break-word;
  text-align: center;
  word-wrap: break-word;
  /* Stupid IE is stupid */
  word-break: break-word;
  /* Still won't do any good in IE, but they will put on my tombstone, 'He hath done what he could' */
}

.sidebar header {
  padding-top: 0;
  margin-top: 0;
}

.sidebar header h1 {
  font-size: 1.25rem;
  font-weight: bold;
  padding-top: 0;
  margin-top: 0;
}

.sidebar header h2 {
  font-size: 1.1rem;
  font-weight: normal;
}

.sidebar-transparent {
  background-color: transparent;
  border: 0;
}

.sidebar .sidebar-title {
  color: var(--rps-text-grey);
  text-align: center;
  font-weight: bold;
}

.sidebar .sidebar-title.colorified {
  color: var(--rps-blue);
  font-weight: bold;
}

.sidebar section {
  margin-top: 1em;
}

.sidebar .sidebar-section-title {
  padding-bottom: 7px;
  border-bottom: 1px solid #D8D8D8;
  font-weight: 700;
}

.sidebar a[href^="mailto:"],
.sidebar a[href^="tel:"] {
  color: var(--rps-dark-green);
}

.sidebar img {
  max-width: 150px;
  width: 100%;
}

.sidebar hr {
  border-top-color: #cacaca;
}

.sidebar-text.text-button {
  color: var(--rps-text-grey);
  cursor: pointer;
}