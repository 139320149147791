:root {
  // Official (approved) colors
  --rps-light-green: #b7bf10;
  --rps-dark-green: #28724f;
  --rps-light-grey: #D9D9D6;
  --rps-dark-grey: #63666A;
  --rps-blue: #0085AD;
  --rps-orange: #FF6A13;
  --rps-purple: #5C068C;
}

:root {
  // Un-official (non-approved) colors
  --rps-green: #009A6B;
  --rps-text-grey: #515151;
  --rps-green-disabled: lighten(desaturate(var(--rps-green), 70%), 40%);
  --rps-grey-disabled: #404040;
  --rps-valid: #42a948;
  --rps-invalid: #a94442;
  --green-300: #15986c;
  --green-400: #00ad78;
  --green-yellow: #e2fedd;
  --green-grey: #7abfaa;
  --green: #3f9c35;
  --green-lime: #00d894;
  --green-success: #00b351;
  --green-offwhite: #e1f3ed;
  --red: #ff0000;
  --red-pink: #ffe7e9;
  --red-alert: #b84947; // "Cha-Ching!!" - Tanya
  --red-error: #cc0000;
  --orange-warning: #ffa003;
  --orange-warning-dark: #ec7a08;
  --orange: #ff9f00;
  --orange-600: #ffa000;
  --orange-peach: #f4a064;
  --orange-peach-dark: #f68c3e;
  --beige: #fff8dc;
  --blue-ocean: #1abc9c;
  --blue-water: #149dcc;
  --blue-sky: #009dd9;
  --blue-link: #005858;
  --black: #000;
  --grey-blue: #586f7f;
  --grey-200: #272d2c;
  --grey-250: #2d3a43;
  --grey-300: #323232;
  --grey-350: #444;
  --grey-400: #515151; // Use ---rps-text-grey instead
  --grey-450: #666;
  --grey: #696969;
  --grey-550: #8b8d8f;
  --grey-600: #999;
  --grey-650: #a5aeb5;
  --grey-700: #adadad;
  --grey-750: #bbb;
  --grey-800: #cacaca;
  --grey-850: #ccc;
  --grey-900: #d7d7d7;
  --grey-950: #d8d8d8;
  --grey-1000: #d9d9d9;
  --white-100: #dbdbdb;
  --white-200: #ddd;
  --white-300: #e2e2e2;
  --white-350: #e4e4e4;
  --white-400: #e5e5e5;
  --white-450: #e6e6e6;
  --white-500: #eee;
  --white-550: #efefef;
  --white-600: #f1f1f1;
  --white-700: #f2f2f2;
  --white-800: #f8f8f8;
  --white-900: #f9f9f9;
  --white: #fff;
}