/* * * * * * * * * * * * * * * * * * * * *
 *       Layout for entire website       *
 * * * * * * * * * * * * * * * * * * * * */
/* THESE ARE SHARED GLOBAL STYLE SHEETS - BE AWARE THAT ANY CHANGE WILL BE SITE WIDE */
/* Please keep component specific styles isolated to your area */
/* Bootstrap stuff */
@use './assets/css/includes/colors';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons';
/* Site-specific stuff */
@import './assets/css/site';
@import './assets/css/faux-material';
@import './assets/css/forms';
@import './assets/css/bootstrap-overrides';
@import './assets/css/placeholder-support';
@import './assets/css/todo-refactor-out/workflow';